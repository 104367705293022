<template>
  <v-card flat class="ma-auto">
    <v-container>
      
         <v-row justify="center">
          <p class="text-h5"> {{$t('Application language settings')}}</p>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-select
              outlined
              dense
              :label="$t('Language')"
              v-model="system.lang"
              :items="langs"
              item-value="id"
              item-text="name"
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4" align="right">
            <v-btn
              color="#196619"
              dark
              min-width="150"
              :loading="loading"
              @click="save"
            >{{ $t("save") }}</v-btn>
          </v-col>
        </v-row>
  
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "Languages",
  data() {
    return {
      ws: false,
      loading: false,
      system: null,
      langs: [
        {id: "cs", name: this.$t('Czech') },
        {id: "en", name: this.$t('English') },
        {id: "es", name: this.$t('Estonian') }
      ]
    };
  },
  methods: {
    async initialize () {
        let res = null
        try { 
          res = await this.$http.get("/system")
          this.system = res.data
        } catch (e) {
          console.log(e)
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        } 
    },
    proceedAction(act) {
      this.action = act;
      this.dialog = true;
    },
    async save() {
      let res = null;
      try {
        this.loading = true;
        res = await this.$http.post(`/system`, this.system);
        this.$emit("alertMsg", {
          type: "success",
          text: "Data saved OK",
        });
      } catch (e) {
        console.log(e);
        this.$emit("alertMsg", {
          type: "error",
          text: e.message + "\n" + res?.error,
        });
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
  created () {
      this.initialize()
    },
};
</script>

<style>
</style>